<script lang="ts">
  import "@fontsource/fira-mono/400.css";
  import "@fontsource/fira-mono/500.css";
  import "@fontsource/fira-mono/700.css";

  // Variable fonts enable many different variations of a typeface to be
  // incorporated into a single file. They offer flexibility and a potential
  // reduction in bundle size if many weights are used.
  import "@fontsource-variable/inter/slnt.css";

  import "../app.css";
  import "../ansi.css";

  import { beforeNavigate } from "$app/navigation";
  import { page, updated } from "$app/stores";

  import Analytics from "$lib/ui/common/Analytics.svelte";
  import Seo from "$lib/ui/common/Seo.svelte";
  import ToastContainer from "$lib/ui/system/ToastContainer.svelte";

  $: title = $page.data.title || "Modal";

  // ##PreventMissingImportModule
  // Detect when our code version has updated and execute a full page reload to prevent
  // svelte kit from dynamically loading a file that no longer exists.
  // see: https://kit.svelte.dev/docs/configuration#version
  beforeNavigate(({ to, willUnload }) => {
    if ($updated && willUnload && to?.url) {
      location.href = to.url.href;
    }
  });
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>

<Seo
  title={$page.data.ogTitle}
  description={$page.data.ogDescription}
  imageUrl={$page.data.ogImageUrl}
/>

{#if $page.url.host === "modal.com"}
  <Analytics />
{/if}

<ToastContainer />

<slot />
